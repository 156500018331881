import React, { Component } from "react";
import { graphql } from "gatsby";
import { Menu, Dropdown, Icon } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

import Layout from "../components/layout";
import Seo from "../components/shared/seo";
import Slices from "../components/shared/Slices";
import Grid from "../styles/Grid";
import colors from "../styles/colors";
import breakpoints from "../styles/breakpoints";
import {
  fontR12m,
  fontFG30m,
  fontR14r,
  fontFG52m,
  fontFG24l,
  fontR16r,
} from "../styles/fonts";
import rightArrowMedium from "../assets/images/arrow-right-medium.svg";
import CTAButton from "../components/shared/CTAButton";

const Title = styled.div`
  ${fontFG52m};
`;

const Brief = styled.div`
  ${fontFG24l};
`;

const Button = styled(CTAButton)`
  ${fontR16r};
  color: ${colors.darkGrey};
  background-color: ${colors.white};
  border: 1px solid ${colors.border};
  margin-bottom: 24px;
  padding: 12px;
  height: auto;
  justify-content: space-between;
  align-items: center;
`;

const LocaleOption = styled.div`
  padding: 10px 32px;
  color: ${(props) => (props.active ? colors.white : colors.disabled)};
  background-color: ${(props) => (props.active ? colors.green : "transparent")};
  border-radius: 100px;
  :hover {
    cursor: pointer;
  }
`;

const LocaleSelector = styled.div`
  padding: 4px;
  margin: 0 auto 60px auto;
  border: 1px solid ${colors.border};
  border-radius: 100px;
`;

const CreatedDate = styled.div`
  ${fontR12m};
  color: ${colors.caption};
  margin-bottom: 16px;
`;

const PressName = styled.div`
  ${fontFG30m};
  margin-bottom: 16px;
`;

const Description = styled.div`
  ${fontR14r};
`;

const Wrapper = styled.div`
  max-width: 900px;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 25px;
  :hover {
    padding: 24px;
    border: 1px solid ${colors.border};
    border-radius: 4px;
    box-shadow: 5px 5px 10px ${colors.shadow};
    transition-delay: all 0.4s;
    cursor: pointer;
    ${PressName} {
      color: ${colors.link};
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    padding: 25px 41px 33px 41px;
    ${Wrapper} {
      margin-right: 24px;
    }
    :hover {
      padding: 24px 40px 32px 40px;
      &:after {
        content: "";
        mask: url(${rightArrowMedium}) no-repeat;
        background-color: ${colors.link};
        width: 16px;
        height: 16px;
        flex: 0 0 16px;
        display: inline-block;
      }
    }
  }
`;

const Items = styled.div``;

const Container = styled(Grid)`
  ${Title} {
    margin: 24px auto;
  }
  ${Brief} {
    margin: 0 auto 40px auto;
  }
  ${LocaleSelector} {
    display: none;
  }
  ${Button} {
    display: flex;
    grid-column: 2 / span 4;
  }
  ${Items} {
    grid-column: 2 / span 4;
  }

  @media (min-width: ${breakpoints.lg}) {
    ${Title} {
      margin: 80px auto 32px auto;
    }
    ${Brief} {
      grid-column: 4 / span 8;
    }
    ${LocaleSelector} {
      display: flex;
    }
    ${Button} {
      display: none;
    }
    ${Items} {
      grid-column: 3 / span 10;
    }
  }
`;

class PressPage extends Component {
  state = {
    selectedPressLocale: "en",
  };

  // componentDidMount() {
  // const pageTitle = this.props.data.prismicPressPage.data.title;
  // window.analytics.page(pageTitle);
  // }

  onLocaleSelect = (localeKey) => {
    this.setState({ selectedPressLocale: localeKey });
  };

  onPressItemClick = (pressLink) => {
    // window.analytics.track(`Clicked ${pressLink}`, { label: "Press" });
    window.open(pressLink);
  };

  render() {
    const {
      location,
      pageContext: { locale },
      data,
    } = this.props;
    const pageData = data.prismicPressPage.data;
    const rawData = data.prismicPressPage.dataRaw;
    const { selectedPressLocale } = this.state;

    const pressLocales = [
      {
        key: "en",
        name: <FormattedMessage id={"english_press"} />,
        data: pageData.english_press,
      },
      {
        key: "id",
        name: <FormattedMessage id={"indonesian_press"} />,
        data: pageData.indonesian_press,
      },
      {
        key: "th",
        name: <FormattedMessage id={"thai_press"} />,
        data: pageData.thai_press,
      },
    ];

    const pressData = pressLocales.find(
      (locale) => locale.key === selectedPressLocale
    ).data;

    const selectedPressLocaleName = pressLocales.find(
      (locale) => locale.key === selectedPressLocale
    ).name;

    const pressLocaleMenu = (
      <Menu>
        {pressLocales.map((locale) => (
          <Menu.Item
            key={locale.key}
            onClick={() => this.onLocaleSelect(locale.key)}
          >
            {locale.name}
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Layout location={location} locale={locale} pageData={pageData}>
        <Seo
          title={`${pageData.title} | Workmate`}
          description={pageData.description}
        />
        <Container>
          <Title>{pageData.title}</Title>

          {/* Locale selector on mobile */}
          <Dropdown overlay={pressLocaleMenu} trigger={["click"]}>
            <Button>
              {selectedPressLocaleName}
              <Icon type="down" />
            </Button>
          </Dropdown>

          {/* Locale selector on desktop */}
          <LocaleSelector>
            {pressLocales.map((locale) => (
              <LocaleOption
                active={selectedPressLocale === locale.key}
                onClick={() => this.onLocaleSelect(locale.key)}
              >
                {locale.name}
              </LocaleOption>
            ))}
          </LocaleSelector>

          <Items>
            {pressData.map((item, index) => (
              <Item
                key={index}
                onClick={() => this.onPressItemClick(item.link)}
              >
                <Wrapper>
                  <CreatedDate>{item.date}</CreatedDate>
                  <PressName>{item.press}</PressName>
                  <Description>{item.description}</Description>
                </Wrapper>
              </Item>
            ))}
          </Items>
        </Container>
        <Slices slices={rawData.body} />
      </Layout>
    );
  }
}

export default PressPage;

export const query = graphql`
  query PressPageQuery($localeKey: String) {
    prismicPressPage(lang: { eq: $localeKey }) {
      dataRaw
      data {
        title
        navigation_cta_button_text
        navigation_cta_button_url
        english_press {
          date
          press
          description
          link
        }
        indonesian_press {
          date
          press
          description
          link
        }
        thai_press {
          date
          press
          description
          link
        }
      }
    }
  }
`;
